import React from 'react';
import styled from 'styled-components';
import Crown from 'images/homepage/Crown';
import PrivateDucky from 'images/homepage/PrivateDucky';
import DurableDucky from 'images/homepage/DurableDucky';
import ReliableDucky from 'images/homepage/ReliableDucky';
import DurableDucky2 from 'images/homepage/DurableDucky2';

const Container = styled.section.attrs({
    className: 'text-center',
})`
    margin-top: 130px;
`;

const Heading = styled.h1`
    margin-top: 5px;
    margin-bottom: 46px;
    font-weight: 800;

    @media (min-width: 992px) {
        font-size: 56px;
    }
`;

const HeadingText = styled.div.attrs({
    className: 'container ps-3 pe-3'
})`
    font-size: 20px;
    font-weight: 400;
`;

const CardsContainer = styled.div.attrs({
    className: 'container',
})`
    margin-top: 64px;
`;

const Card = styled.div`
    background: var(--color-bg-gray);
    border-radius: 24px;
    padding: 40px;
`;

const SubHeading = styled.h2`
    font-weight: 800;
    font-size: 24px;

    @media(min-width: 992px) {
        font-size: 37px;
    }
`;

const Point = styled.span`
    color: #1db954;
`;

const points = [
    {
        heading: 'Private',
        image: <PrivateDucky />,
        content: [
            <>
                We are{' '}
                <a href="/architecture">
                    <strong>end-to-end encrypted</strong>
                </a>
                . This means, we have zero knowledge of your data and only you
                can see your photos.
            </>,
            <>
                Our open source code has been audited by reputed cryptographers.
            </>,
        ],
    },
    {
        heading: 'Durable',
        image: <DurableDucky2></DurableDucky2>,
        content: [
            <>
                We are a sustainable business driven by revenue from your
                subscriptions
            </>,
            <>
                We use our revenue to store{' '}
                <a href="/reliability"><strong>3 copies of your data</strong></a> in 3 locations. One of
                them is in an underground fallout shelter.
            </>,
        ],
    },
    {
        heading: 'Reliable',
        image: <ReliableDucky />,
        content: [
            <>
                You can reliably view, share, organize and download your data,
                in original quality, on all platforms.
            </>,
            <>
                Last, but not least, you can always talk to a{' '}
                <strong>human@ente.io</strong>.
            </>,
        ],
    },
];

export default function WhyEnte() {
    // return null;
    return (
        <Container>
            <Crown />
            <Heading>Why use Ente?</Heading>
            <div className="container ps-3 pe-3">
                End-to-end encryption, durable storage and simple sharing.
                <br />
                We have packed these and much more into our beautiful open
                source apps.
            </div>
            <CardsContainer>
                <div className="row">
                    {points.map((point, index) => (
                        <div className="col-lg-4 col-md-12 px-4 d-flex align-items-stretch">
                            <Card>
                                {point.image}
                                <SubHeading className="text-start">
                                    <Point>{`0${index + 1}`.slice(-2)}</Point>{' '}
                                    {point.heading}
                                </SubHeading>
                                {point.content.map((text) => (
                                    <p className="text-start">{text}</p>
                                ))}
                            </Card>
                        </div>
                    ))}
                </div>
            </CardsContainer>
        </Container>
    );
}
