import React from 'react';
import { ISVGProps } from 'types';

export default function EnteIcon(props: ISVGProps) {
    return (
        <svg width="290" height="277" viewBox="0 0 290 277" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g filter="url(#filter0_d_734_1751)">
                <rect x="49" y="30.959" width="192" height="178" rx="35" fill="url(#paint0_linear_734_1751)" />
            </g>
            <path d="M104.637 135.959C101.712 135.959 99.1379 135.4 96.913 134.281C94.7199 133.162 93.0195 131.639 91.8117 129.712C90.6039 127.754 90 125.532 90 123.046C90 120.529 90.588 118.307 91.764 116.38C92.9718 114.422 94.6087 112.899 96.6747 111.812C98.7406 110.693 101.077 110.133 103.683 110.133C106.194 110.133 108.451 110.662 110.453 111.718C112.487 112.744 114.092 114.236 115.268 116.194C116.444 118.12 117.032 120.436 117.032 123.139C117.032 123.419 117.016 123.745 116.985 124.118C116.953 124.46 116.921 124.787 116.889 125.097H96.0549V120.855H112.98L110.119 122.114C110.119 120.809 109.849 119.674 109.309 118.711C108.769 117.747 108.022 117.002 107.068 116.473C106.115 115.914 105.002 115.634 103.731 115.634C102.459 115.634 101.331 115.914 100.346 116.473C99.3922 117.002 98.6453 117.763 98.1049 118.757C97.5646 119.721 97.2944 120.871 97.2944 122.207V123.326C97.2944 124.693 97.5964 125.905 98.2003 126.962C98.836 127.988 99.71 128.78 100.822 129.339C101.967 129.868 103.302 130.132 104.827 130.132C106.194 130.132 107.386 129.93 108.403 129.526C109.452 129.122 110.405 128.516 111.264 127.708L115.221 131.903C114.045 133.209 112.567 134.219 110.787 134.933C109.007 135.617 106.957 135.959 104.637 135.959Z" fill="white" />
            <path d="M137.574 110.133C139.608 110.133 141.42 110.537 143.009 111.345C144.63 112.122 145.902 113.334 146.823 114.982C147.745 116.598 148.206 118.68 148.206 121.228V135.586H140.768V122.347C140.768 120.327 140.307 118.835 139.386 117.872C138.496 116.908 137.224 116.427 135.572 116.427C134.396 116.427 133.331 116.675 132.377 117.173C131.456 117.639 130.725 118.369 130.184 119.363C129.676 120.358 129.421 121.632 129.421 123.186V135.586H121.984V110.506H129.088V117.452L127.753 115.354C128.675 113.676 129.994 112.387 131.71 111.485C133.426 110.584 135.381 110.133 137.574 110.133Z" fill="white" />
            <path d="M165.436 135.959C162.417 135.959 160.065 135.213 158.38 133.721C156.696 132.199 155.853 129.945 155.853 126.962V104.959H163.291V126.869C163.291 127.925 163.577 128.749 164.149 129.339C164.721 129.899 165.5 130.179 166.485 130.179C167.661 130.179 168.662 129.868 169.489 129.246L171.491 134.374C170.728 134.902 169.807 135.306 168.726 135.586C167.677 135.835 166.581 135.959 165.436 135.959ZM151.896 116.66V111.066H169.679V116.66H151.896Z" fill="white" />
            <path d="M187.604 135.959C184.68 135.959 182.106 135.4 179.881 134.281C177.688 133.162 175.987 131.639 174.779 129.712C173.572 127.754 172.968 125.532 172.968 123.046C172.968 120.529 173.556 118.307 174.732 116.38C175.939 114.422 177.576 112.899 179.642 111.812C181.708 110.693 184.044 110.133 186.651 110.133C189.162 110.133 191.418 110.662 193.421 111.718C195.455 112.744 197.06 114.236 198.236 116.194C199.412 118.12 200 120.436 200 123.139C200 123.419 199.984 123.745 199.952 124.118C199.921 124.46 199.889 124.787 199.857 125.097H179.022V120.855H195.948L193.087 122.114C193.087 120.809 192.817 119.674 192.276 118.711C191.736 117.747 190.989 117.002 190.036 116.473C189.082 115.914 187.97 115.634 186.698 115.634C185.427 115.634 184.299 115.914 183.313 116.473C182.36 117.002 181.613 117.763 181.073 118.757C180.532 119.721 180.262 120.871 180.262 122.207V123.326C180.262 124.693 180.564 125.905 181.168 126.962C181.804 127.988 182.678 128.78 183.79 129.339C184.934 129.868 186.269 130.132 187.795 130.132C189.162 130.132 190.354 129.93 191.371 129.526C192.419 129.122 193.373 128.516 194.231 127.708L198.188 131.903C197.012 133.209 195.534 134.219 193.754 134.933C191.975 135.617 189.924 135.959 187.604 135.959Z" fill="white" />
            <defs>
                <filter id="filter0_d_734_1751" x="0" y="0.958984" width="290" height="276" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="19" />
                    <feGaussianBlur stdDeviation="24.5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.359395 0 0 0 0 0.708333 0 0 0 0 0.288253 0 0 0 1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_734_1751" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_734_1751" result="shape" />
                </filter>
                <linearGradient id="paint0_linear_734_1751" x1="145" y1="101.459" x2="145" y2="255.459" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#494949" />
                    <stop offset="1" stop-color="#161616" />
                </linearGradient>
            </defs>
        </svg>
    );
}