import { DownloadURL } from 'constants/download';
import HeroDucky2 from 'images/homepage/HeroDucky2';
import React from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import { FluidImageData } from 'types';
import { OS, getDeviceOS } from 'utils/detect-os';

interface IProps {
    images: FluidImageData[];
}

const Container = styled.section.attrs({
    className: 'container',
})`
    display: flex;
    align-items: stretch;
`;

const BackDrop = styled.div.attrs({
    className:
        'row d-flex flex-column flex-lg-row g-0 pt-0 pb-0 pt-lg-5 pb-lg-5',
})`
    color: #0c4401;
    border-radius: 51px;
    width: 100%;
    display: flex;
    align-items: center;
    background: radial-gradient(
            70.11% 70.11% at 50% 50%,
            #86ff6d 0%,
            #67d451 32.99%,
            #58bf43 60.59%
        ),
        #58bf43;
`;

const Heading = styled.h1`
    font-weight: 700;
    font-size: 40px;
    color: #313131 !important;
    line-height: 100%;

    @media (min-width: 1200px) {
        font-size: 50px;
    }

    @media (min-width: 1400px) {
        font-size: 60px;
    }
`;

const Highlight = styled.span`
    color: #fff;
`;

const HeroDuckyImage = styled(HeroDucky2)`
    position: absolute;
    right: 100px;
    bottom: 100px;
    z-index: -1;
`;

const Paragraph = styled.p`
    font-weight: 500;
    font-size: 16px;
    padding: 4px;
    color: #0c4401;

    @media (min-width: 1200px) {
        font-size: 18px;
    }

    @media (min-width: 1400px) {
        font-size: 22px;
    }
`;

const Picture = styled.picture<{
    aspectRatio: { light: string; dark: string };
}>`
    & > img {
        aspect-ratio: ${(props) => props.aspectRatio.light} / 1;
    }

    @media (prefers-color-scheme: dark) {
        & > img {
            aspect-ratio: ${(props) => props.aspectRatio.dark} / 1;
        }
    }
`;

const Image = styled.img`
    position: relative;
    z-index: 1;
    width: 245px;
    transform: rotate(8deg) translate(-10%, 0%);
    scale: 0.8;

    @media (min-width: 992px) {
        transform: rotate(8deg) translate(-15%, 0%);
        scale: 1.3;
    }
`;

const CtaButton = styled.button`
    border: none;
    background: #222222;
    padding: 16px 12px;
    color: #fff;
    border-radius: 20px;
    margin-top: 12px;
    font-weight: 700;
    font-size: 18px;
    font-family: Gilroy;
    width: 200px;
    position: relative;
    overflow: hidden;
    align-items: center;
    letter-spacing: 0.8px;

    &::before {
        content: ' ';
        background: #9c9c9c;
        filter: blur(20px);
        position: absolute;
        left: 0;
        width: 12px;
        top: -100%;
        height: 400%;
        animation: shine 4s linear infinite;
    }

    @media (min-width: 992px) {
        padding: 18px 12px;
        width: 300px;
    }
`;

const HeadingContainer = styled.div.attrs({
    className: 'col-lg-5 text-center text-lg-start',
})`
    padding: 0 10px;

    @media (min-width: 992px) {
        padding-left: 100px !important;
    }
`;

const DownloadCTA: React.FC = () => {
    const handleClick = () => {
        const os = getDeviceOS();
        if (os === OS.Android) {
            window.location.href = DownloadURL.googlePlay;
        } else if (os === OS.IOS) {
            window.location.href = DownloadURL.appStore;
        } else {
            const downloadSection = document.getElementById('download');
            if (downloadSection) {
                downloadSection.scrollIntoView({
                    behavior: 'smooth',
                    block: 'end',
                });
            }
        }
    };

    return (
        <CtaButton onClick={handleClick}>
            {isMobile ? 'Get started' : 'Get started'}
            {/* TODO: Update string to "Download" */}
        </CtaButton>
    );
};

export default function HeroSection(props: IProps) {
    return (
        <Container>
            <BackDrop>
                <HeadingContainer>
                    <Heading>
                        <Highlight>Safe home</Highlight>
                        <br />
                        for your photos
                    </Heading>
                    <Paragraph>
                        Store, share, and rediscover your memories with absolute
                        privacy
                    </Paragraph>
                    <DownloadCTA />
                </HeadingContainer>
                <div className="col-md-7 p-0 d-flex position-relative justify-content-center">
                    <Picture
                        aspectRatio={{
                            light: props.images[0].fluid.aspectRatio,
                            dark: props.images[1].fluid.aspectRatio,
                        }}>
                        <source
                            srcSet={props.images[1].fluid.srcSetWebp}
                            media="(prefers-color-scheme: dark)"
                        />
                        <Image
                            fetch-priority="high"
                            srcSet={props.images[0].fluid.srcSetWebp}
                            alt="Ente App Screenshot"
                        />
                    </Picture>
                    <div
                        className="position-absolute"
                        style={{
                            bottom: '-10%',
                            left: '35%',
                        }}>
                        <HeroDucky2 />
                    </div>
                </div>
            </BackDrop>
        </Container>
    );
}
