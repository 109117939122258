import { fallbackPriceResponse } from 'data/fallback-price-response';

/** The plans, as expected and used by the rest of our code */
export interface IPlan {
    id: string;
    /** The formatted size of the plan in GBs */
    size: string;
    /** Formatted price. Includes the currency symbol. */
    price: string;
    period: PlanPeriod;
}

type PlanPeriod = 'month' | 'year';

export const fetchPlans = async () => {
    try {
        const response = await fetch('https://api.ente.io/billing/plans/v2');
        if (response.ok) {
            const jsonResponse = await response.json();
            return convertAPIPlans(jsonResponse);
        }
    } catch (error) {
        console.log(error);
    }

    // In case the dynamic API request to fetch the pricing fails, or something
    // happens when converting them, fallback to the default set of plans.
    //
    // This conversion is not expected to fail since it uses a bundled value.
    return convertAPIPlans(fallbackPriceResponse);
};

interface IPlansResponse {
    plans: IPlansResponsePlan[];
}

interface IPlansResponsePlan {
    id?: string;
    /** Plan storage, in bytes */
    storage?: number;
    /**
     * Plan price.
     *
     * Formatted string. Includes the currency symbol.
     */
    price?: string;
    period?: 'month' | 'year' | string;
}

/** Convert the plans API response to the plans structure used by the UI code */
const convertAPIPlans = (response: IPlansResponse) => {
    // Ignore invalid plans
    return response.plans
        .map(({ id, storage, price, period }) => {
            if (!id || !storage || !price || !period) return;
            if (period != 'month' && period != 'year') return;

            return {
                id,
                price,
                period,
                size: `${convertStorage(storage)}`,
            };
        })
        .filter((p) => p) as IPlan[];
};

/**
 * Convert the storage value received in the API response to a string that we
 * show to the user in the UI
 */
const convertStorage = (storage: number) => {
    return Math.ceil(storage / (1024 * 1024 * 1024));
};
