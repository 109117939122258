/**
 A fallback response for GET /billing/plans/v2, returning prices in USD.
 Used as a fallback in case we can't fetch prices from the API.

 Last updated: 29 May 2022
 Corresponding museum commit: 329d366859ec3dfcd39cbf944353133f73fa745b
 CLI: curl -H 'CF-IPCountry: US' 'http://localhost:8080/billing/plans/v2'
 */
export const fallbackPriceResponse = {
    freePlan: {
        duration: 365,
        period: 'days',
        storage: 1073741824,
    },
    plans: [
        {
            id: '10gb_monthly',
            androidID: '10gb_monthly',
            iosID: '10gb_monthly',
            paypalID: '10gb_monthly',
            stripeID: 'price_1JI6kxK59oeucIMOgnsZ2eiJ',
            storage: 10737418240,
            price: '$0.99',
            period: 'month',
        },
        {
            id: '10gb_yearly',
            androidID: '',
            iosID: '',
            paypalID: '10gb_yearly',
            stripeID: 'price_1JI6kxK59oeucIMO8inYshKQ',
            storage: 10737418240,
            price: '$9.99',
            period: 'year',
        },
        {
            id: '100gb_monthly',
            androidID: '100gb_monthly',
            iosID: '100gb_monthly',
            paypalID: '100gb_monthly',
            stripeID: 'price_1Ih8h7K59oeucIMOEP1m0ECI',
            storage: 107374182400,
            price: '$4.99',
            period: 'month',
        },
        {
            id: '100gb_yearly',
            androidID: '',
            iosID: '',
            paypalID: '100gb_yearly',
            stripeID: 'price_1Ih8h7K59oeucIMO0gi1eoUT',
            storage: 107374182400,
            price: '$49.99',
            period: 'year',
        },
        {
            id: '500gb_monthly',
            androidID: '500gb_monthly',
            iosID: '500gb_monthly',
            paypalID: '500gb_monthly',
            stripeID: 'price_1JprPiK59oeucIMOndO6JaAm',
            storage: 536870912000,
            price: '$9.99',
            period: 'month',
        },
        {
            id: '500gb_yearly',
            androidID: '',
            iosID: '',
            paypalID: '500gb_yearly',
            stripeID: 'price_1JprPiK59oeucIMOPYWjA67a',
            storage: 536870912000,
            price: '$99.99',
            period: 'year',
        },
        {
            id: '2000gb_monthly',
            androidID: '2000gb_monthly',
            iosID: '2000gb_monthly',
            paypalID: '2000gb_monthly',
            stripeID: 'price_1JI7QnK59oeucIMOyyaRoVDv',
            storage: 2147483648000,
            price: '$19.99',
            period: 'month',
        },
        {
            id: '2000gb_yearly',
            androidID: '',
            iosID: '',
            paypalID: '2000gb_yearly',
            stripeID: 'price_1JI7QnK59oeucIMOOyljbdo4',
            storage: 2147483648000,
            price: '$199.99',
            period: 'year',
        },
    ],
};

export default fallbackPriceResponse;
