import React from 'react';
import styled from 'styled-components';
import { ISVGProps } from 'types';

const Group = styled.g`
    transform-origin: 20px 240px;
    animation: rotate-5 8s linear infinite;
`;

export default function FAQ(props: ISVGProps) {
    return (
        <svg width="283" height="291" viewBox="0 0 283 291" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <Group>
                <path d="M175.205 32.317C116.282 14.5086 53.7687 48.0048 35.9319 107.022C27.7956 133.942 29.932 162.221 41.9364 187.421L23.493 220.574C19.3554 228.129 26.0656 236.593 34.1349 234.987L73.2855 226.788C84.2019 235.532 96.7059 242.085 110.542 246.267C169.465 264.075 231.95 230.673 249.787 171.656C267.624 112.639 234.128 50.1256 175.205 32.317ZM132.635 196.959C127.715 199.596 121.831 197.553 119.364 192.948C116.775 187.786 118.538 182.268 123.375 179.676C127.991 177.203 133.917 178.592 136.647 183.687C138.882 187.858 138.044 194.061 132.635 196.959ZM154.453 148.904C152.343 149.088 149.882 150.09 149.143 152.538L147.407 158.279C145.843 163.456 140.327 166.412 135.15 164.847C129.879 163.254 127.017 157.767 128.582 152.59L130.317 146.848C133.276 137.059 142.134 130.183 152.873 129.32C161.718 128.603 168.683 121.874 169.832 112.975C170.807 105.669 166.764 97.975 160.196 94.5518C149.51 88.9321 137.092 94.5229 133.783 105.47C132.219 110.647 126.703 113.603 121.526 112.038C116.349 110.474 113.393 104.958 114.958 99.7808C121.917 76.756 148.09 65.9385 169.377 77.0902C183.114 84.3235 191.369 100.173 189.407 115.502C186.996 133.675 172.671 147.425 154.453 148.904Z" fill="var(--color-secondary-highlight)" />
            </Group>
        </svg>
    );
}