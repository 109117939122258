import React from 'react';
import styled from 'styled-components';
import { ISVGProps } from 'types';

const SVG = styled.svg`
    & > #vector-159 {
        stroke-dasharray: 320;
        stroke-dashoffset: 320;
    }

    &.sal-animate > #vector-159 {
        animation: dash 3s linear;
        animation-fill-mode: forwards;
    }

    & > #vector-161{
        stroke-dasharray: 320;
        stroke-dashoffset: 320;
    }

    &.sal-animate > #vector-161 {
        animation: dash 3s linear;
        animation-delay: .6s;
        animation-fill-mode: forwards;
    }

    & > #vector-160{
        stroke-dasharray: 320;
        stroke-dashoffset: 320;
    }

    &.sal-animate > #vector-160 {
        animation: dash 3s linear;
        animation-delay: 1.5s;
        animation-fill-mode: forwards;
    }

    & > #vector-162{
        stroke-dasharray: 320;
        stroke-dashoffset: 320;
    }

    &.sal-animate > #vector-162 {
        animation: dash 3s linear;
        animation-delay: 1.8s;
        animation-fill-mode: forwards;
    }
`;

export default function Crown(props: ISVGProps) {
    return (
        <SVG height="53" width="100" viewBox="0 0 120 71" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} data-sal="dash">
            <path id="vector-161" d="M57.5 62V9" stroke="var(--color-primary-highlight)" stroke-width="17" stroke-linecap="round" stroke-linejoin="round" />
            <path id="vector-159" d="M30 62L9 36" stroke="var(--color-primary-highlight)" stroke-width="17" stroke-linecap="round" stroke-linejoin="round" />
            <path id="vector-160" d="M86 62L96.5 41" stroke="var(--color-primary-highlight)" stroke-width="17" stroke-linecap="round" stroke-linejoin="round" />
            <path id="vector-162" d="M109.5 19.5L111.5 16" stroke="var(--color-primary-highlight)" stroke-width="17" stroke-linecap="round" stroke-linejoin="round" />
        </SVG>
    );
}
