import Lottie from 'react-lottie';
import * as jumpingDucky from 'images/homepage/ducky-jump.json';
import React from 'react';

export default class JumpingDucky extends React.Component<
    {},
    { isStopped: boolean; isPaused: boolean }
> {
    constructor(props: {}) {
        super(props);
        this.state = { isStopped: false, isPaused: false };
    }

    render() {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: jumpingDucky,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
            },
        };

        return (
            <div>
                <Lottie
                    options={defaultOptions}
                    height={300}
                    width={400}
                    isStopped={this.state.isStopped}
                    isPaused={this.state.isPaused}
                />
            </div>
        );
    }
}
