import React from 'react';
import styled from 'styled-components';
import { ISVGProps } from 'types';

const Group = styled.g`
    transform-origin: 90px 33px;
    animation: rotate-5 4s linear infinite;
`;

export default function Lock(props: ISVGProps) {
    return (
        <svg width="164" height="215" viewBox="-14 0 184 215" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <Group>
                <path d="M139.792 83.5691L142.039 57.4577C144.52 28.6219 123.151 3.22276 94.3099 0.740868C65.4742 -1.74052 40.075 19.6283 37.5931 48.4698L35.3462 74.5813C20.9239 73.3402 8.22883 84.0235 6.98776 98.4457L0.246894 176.78C-0.994174 191.202 9.68916 203.897 24.1114 205.138L128.557 214.126C142.979 215.367 155.674 204.684 156.916 190.262L163.656 111.927C164.897 97.5053 154.214 84.8102 139.792 83.5691ZM63.7045 50.7168C64.9456 36.2946 77.6436 25.6115 92.0629 26.8523C106.485 28.0934 117.168 40.7914 115.927 55.2107L113.68 81.3221L61.4576 76.8282L63.7045 50.7168Z" fill="var(--color-secondary-highlight)"/>
            </Group>
        </svg>
    );
}