import Toggle from 'components/Toggle';
import { Link } from 'gatsby';
import { useIsDarkMode } from 'hooks/use-media-query';
import ArrowRight from 'images/homepage/ArrowRight';
import YellowCrown from 'images/homepage/YellowCrown';
import React, { ReactNode, useEffect, useState } from 'react';
import { Carousel } from 'react-bootstrap';
import styled from 'styled-components';
import { IPlan, fetchPlans } from 'utils/plan-service';

const Container = styled.section.attrs({
    className: 'container text-center',
})`
    margin-top: 120px;
    padding-top: 120px;
    margin-bottom: 180px;
`;

const Heading = styled.h1`
    font-weight: 800;

    @media (min-width: 992px) {
        font-size: 56px;
    }
`;

const FreeTrial = styled.span`
    color: #1db954;
`;

const SubHeading = styled.div`
    max-width: 700px;
    margin: 0 auto;
    margin-bottom: 70px;
    font-size: 20px;
`;

const Label = styled.div<{ active: boolean }>`
    flex: 1;
    font-weight: 700;

    & > span {
        transition: opacity 0.25s ease-out;
        opacity: ${(props) => (props.active ? '1' : '0.5')};
    }
`;

const Save = styled.div`
    display: inline-block;
    background-color: #ffcd3f;
    color: #434343;
    font-family: 'InterVariable';
    border-radius: 59px;
    padding: 4px 12px;
    margin-left: 8px;
    font-weight: 700;
`;

const CardWrapper = styled.div`
    background: linear-gradient(180deg, #d8d8d855 0%, #a0e2b7 100%);
    padding: 2px;
    border-radius: 17px;
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    flex: 1;
`;

const Card = styled.div`
    border-radius: 15px;
    background: #fff;
    padding: 28px 50px 60px 50px;
    font-family: Gilroy;
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
        padding: 28px;
    }
`;

const PlanLabel = styled.div`
    color: #1d1d1f;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    opacity: 0.5;
    margin-bottom: 7px;
`;

const PlanValue = styled.div`
    color: #1d1d1f;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 21px;
`;

const PlanValueBold = styled(PlanValue)`
    font-weight: 700;
`;

const Popular = styled(PlanLabel)`
    color: #ffcd3f;
    font-weight: 700;
    opacity: 1;
    margin-top: -28px;
    background: linear-gradient(180deg, #20d861 0%, #18ab4c 100%);
    padding: 14px 0;
    text-align: center;
    width: 100px;
    color: #fff;
    border-radius: 0 0 12px 12px;
`;

const PlanPeriod = styled.span`
    color: #272522;
    font-family: 'InterVariable';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`;

const PlanDescription = styled.p`
    color: #333;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 160.5%;
    flex: 1;
    margin-top: 0;
    margin-bottom: 72px;
`;

const PlanCTA = styled.button`
    font-family: 'InterVariable';
    font-weight: 600;
    border: none;
    color: #fff;
    border-radius: 100px;
    background: #434343;
    padding: 10px 26px;
    width: 160px;
    display: inline-flex;
    justify-content: space-between;
    transition: width 0.1s linear, background-color 0.1s linear;
    text-decoration: none;

    &:focus {
        outline: 4px solid rgba(0, 0, 0, 0.2);
    }

    &:hover {
        width: 200px;
        background-color: #1db954;
    }
`;

const PricingCarouselItem = styled(Carousel.Item)`
    transition: transform 0.2s ease;
`;

const getPlanCard = (
    plan: IPlan,
    shouldShowMonthly: boolean,
    title: string,
    description: ReactNode,
    isPopular?: boolean
) => {
    return (
        <CardWrapper>
            <Card>
                {isPopular ? (
                    <Popular>{title}</Popular>
                ) : (
                    <PlanLabel>{title}</PlanLabel>
                )}
                <PlanValue>
                    {plan.price}
                    <PlanPeriod>/{shouldShowMonthly ? 'mo' : 'yr'}</PlanPeriod>
                </PlanValue>
                <PlanLabel>Storage</PlanLabel>
                <PlanValueBold>{plan.size}GB</PlanValueBold>
                <PlanDescription>{description}</PlanDescription>
                <Link to="#download" tabIndex={-1}>
                    <PlanCTA>
                        Try it now <ArrowRight />
                    </PlanCTA>
                </Link>
            </Card>
        </CardWrapper>
    );
};

const planDescription = [
    <>Casual users and those with limited storage needs</>,
    <>Enthusiasts who capture and store more photos</>,
    <>Most users; offers a balanced mix of storage and value</>,
    <>Professionals, heavy users, and content creators</>,
];

const getPlanCards = (
    filteredPlans: IPlan[],
    shouldShowMonthly: boolean,
    carousel?: boolean
) => {
    return filteredPlans.map((plan, index) => {
        const isPopular = index === filteredPlans.length - 2;
        const title = ['Basic', 'Standard', 'Popular', 'Pro'];
        return carousel ? (
            <PricingCarouselItem
                key={plan.id}>
                {getPlanCard(
                    plan,
                    shouldShowMonthly,
                    title[index],
                    planDescription[index],
                    isPopular
                )}
            </PricingCarouselItem>
        ) : (
            <div
                className="col-md-6 col-lg-3 text-start mb-3 d-flex"
                key={plan.id}>
                {getPlanCard(
                    plan,
                    shouldShowMonthly,
                    title[index],
                    planDescription[index],
                    isPopular
                )}
            </div>
        );
    });
};

export default function Pricing() {
    const [plans, setPlans] = useState([] as IPlan[]);
    const [shouldShowMonthly, setShouldShowMonthly] = useState(true);
    const isDarkMode = useIsDarkMode();

    useEffect(() => {
        fetchPlans().then(setPlans);
    }, []);

    const handleToggle = function () {
        setShouldShowMonthly(!shouldShowMonthly);
    };

    const filterPeriod = shouldShowMonthly ? 'month' : 'year';
    const filteredPlans = plans.filter((plan) => plan.period === filterPeriod);

    return (
        <Container id="pricing">
            <YellowCrown />
            <Heading>
                Pricing <FreeTrial>Plans</FreeTrial>
            </Heading>
            <SubHeading>
                Free trial of 1 GB for a year.
                <br />
                Every plan can be shared with your family.
            </SubHeading>
            <div className="d-flex align-items-center">
                <Label
                    active={shouldShowMonthly}
                    className="text-sm-end text-center">
                    <span>Monthly</span>
                </Label>
                <div>
                    <Toggle
                        isOn={!shouldShowMonthly}
                        offLabel=""
                        onLabel=""
                        onToggle={handleToggle}
                    />
                </div>
                <Label
                    active={!shouldShowMonthly}
                    className="text-sm-start text-center">
                    <span>Annually</span> <Save>Save 17%</Save>
                </Label>
            </div>
            <div className="mt-5 d-md-none text-start">
                <Carousel interval={null} className="carousel-dark">
                    {getPlanCards(filteredPlans, shouldShowMonthly, true)}
                </Carousel>
            </div>
            <div className="row mt-5 d-none d-md-flex">
                {getPlanCards(filteredPlans, shouldShowMonthly)}
            </div>
        </Container>
    );
}
