import Plus from 'images/homepage/Plus';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

interface IProps {
    question: ReactNode;
    answer: ReactNode;
    expanded: boolean;
    onClick: () => void;
}

const Card = styled.button<{ expanded: boolean }>`
    color: var(--bs-code-color);
    background: none;
    display: block;
    text-align: left;
    width: 100%;
    padding: 24px;
    border-radius: 16px;
    outline: none;
    margin-bottom: 16px;
    border: ${(props) =>
        props.expanded ? '1px solid #E8E8E8' : '1px solid var(--bs-body-bg)'};
    box-shadow: ${(props) =>
        props.expanded ? '0px 4px 15px -4px rgba(0, 0, 0, 0.16)' : 'none'};

    @media (prefers-color-scheme: dark) {
        border: 1px solid transparent;
        background-color: ${(props) =>
            props.expanded ? '#151515' : 'transparent'};
        box-shadow: ${(props) =>
            props.expanded ? '0px 4px 15px -4px rgba(0, 0, 0, 0.16)' : 'none'};
    }

    &:hover,
    &:focus {
        @media (prefers-color-scheme: light) {
            box-shadow: 0px 4px 15px -4px rgba(0, 0, 0, 0.16);
        }

        @media (prefers-color-scheme: dark) {
            box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.48);
            transition: all 0.15s ease-in;
        }
    }
`;

const Question = styled.p.attrs({
    className: 'd-flex align-items-center',
})<{ expanded: boolean }>`
    margin-top: 0;
    opacity: ${(props) => (props.expanded ? '1' : '0.6')};
    margin-bottom: ${(props) => (props.expanded ? '10px' : '0')};

    & > strong {
        flex: 1;
    }

    & > svg {
        width: 24px;
        min-height: 24px;
        margin-left: 10px;
        transform: rotate(${(props) => (props.expanded ? '225deg' : '0deg')});
        transition: transform 0.25s ease-out;
    }
`;

const Answer = styled.div<{ height: number; expanded: boolean }>`
    overflow: hidden;
    height: ${(props) => (props.expanded ? props.height : 0)}px;

    transition: height 0.25s ease-out;
`;

export default function FAQCard({
    question,
    answer,
    expanded,
    onClick,
}: IProps) {
    const [height, setHeight] = useState(0);
    const ansRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (ansRef.current) {
            setHeight(ansRef.current.scrollHeight || 0);
        }
    }, [ansRef.current]);

    return (
        <Card onClick={onClick} expanded={expanded}>
            <Question expanded={expanded}>
                <strong>{question}</strong>
                <Plus height="24" width="24" />
            </Question>
            <Answer height={height} expanded={expanded} ref={ansRef}>
                {answer}
            </Answer>
        </Card>
    );
}
