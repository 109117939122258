import React from 'react';
import styled from 'styled-components';
import HeartDucky from 'images/download/HeartDucky';
import { StaticImage } from 'gatsby-plugin-image';
import EnteIcon from 'components/EnteIcon'; 
import { AppStoreButton, PlayStoreButton } from 'components/DownloadButtons';

const Container = styled.section.attrs({
    className: 'container'
})``;

const HeartDuckyContainer = styled.div.attrs({
    className: 'd-flex justify-content-end'
})`
    margin-bottom: -250px;

    @media(min-width: 992px) {
        margin-bottom: -300px;
    }
`;

const ExperienceEnteContainer = styled.div`
    border-radius: 50px;
    background: #58BF43;
    position: relative;
    padding: 30px;
    align-items: center;

    @media(min-width: 992px) {
        padding: 0 100px;
    }
`;

const Ente = styled(EnteIcon)`
    position: absolute;
    top: -130px;
    left: 30px;

    @media(min-width: 992px) {
        left: 60px;
    }
`;

const Heading = styled.h1`
    color: #2C2C2C !important;
    font-size: 36px;
    font-style: normal;
    font-weight: 800;
    line-height: 130%;
    text-align: left;

    @media(min-width: 992px) {
        font-size: 40px;
    }
`;
    
const SubHeading = styled.p`
    margin-bottom: 50px;
    color: #444;
    font-size: 18px;
    font-weight: 400;
    margin-top: 0;
`;

const QrContainer = styled.div`
    background-color: #fff;
    border-radius: 16px;
    padding: 20px;
    height: 180px;
    width: 180px;
    margin-bottom: 20px;
`;

export default function ExperienceEnte() {
    return (
        <Container>
            <HeartDuckyContainer>
                <HeartDucky />
            </HeartDuckyContainer>
            <ExperienceEnteContainer>
                <Ente width="192px" />
                <div className='row'>
                    <div className='col-lg-4 col-xl-6 d-flex flex-column justify-content-center'>
                        <Heading>Experience Ente Photos</Heading>
                        <SubHeading className='d-none d-lg-flex'>Scan the QR code to download the app</SubHeading>
                    </div>
                    <div className='col-sm-6 col-lg-4 col-xl-3 d-flex flex-column align-items-center p-lg-4'>
                        <QrContainer className='d-none d-lg-flex'>
                            <StaticImage
                                src="../../images/download/play-store-qr.png"
                                width={160}
                                height={160}
                                alt="Download Ente on the Google Play Store"
                                objectFit="contain"
                                placeholder="none"
                                loading='lazy'
                            />
                        </QrContainer>
                        <PlayStoreButton />
                    </div>
                    <div className='col-sm-6 col-lg-4 col-xl-3 d-flex flex-column align-items-center p-lg-4'>
                        <QrContainer className='d-none d-lg-flex'>
                            <StaticImage
                                src="../../images/download/app-store-qr.png"
                                width={160}
                                height={160}
                                alt="Download Ente on the Google Play Store"
                                objectFit="contain"
                                placeholder="none"
                                loading='lazy'
                            />
                        </QrContainer>
                        <AppStoreButton />
                    </div>
                </div>
            </ExperienceEnteContainer>
        </Container>
    )
}