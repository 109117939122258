import React from 'react';
import { ISVGProps } from 'types';

export default function AndroidPolice(props: ISVGProps) {
    return (
        <svg width="207" height="46" viewBox="0 0 207 46" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g opacity="0.5">
                <path d="M22.1721 0.564453C22.1721 0.564453 25.4842 6.27185 26.6554 8.31921C26.6966 8.40636 26.7645 8.47831 26.8493 8.5248C26.9341 8.57129 27.0315 8.58994 27.1276 8.57808C36.0837 8.57314 54.4035 8.57387 54.4035 8.57387L41.3549 31.1274C41.3549 31.1274 36.8876 23.4168 35.365 20.8005C35.3199 20.7413 35.295 20.6693 35.2938 20.5951C35.2927 20.5208 35.3153 20.4481 35.3586 20.3875C35.6084 19.9811 36.0948 19.1226 36.0948 19.1226H32.9186C32.9186 19.1226 39.4343 30.3835 42.1345 35.0439C42.188 35.108 42.2197 35.1875 42.2249 35.2707C42.2301 35.3539 42.2087 35.4366 42.1636 35.5069C40.2174 38.8492 36.2264 45.7379 36.2264 45.7379C36.2264 45.7379 31.9953 38.5079 29.9972 35.0412C29.9662 34.9689 29.9131 34.9082 29.8454 34.8676C29.7777 34.8271 29.6989 34.8087 29.6202 34.8153C24.6616 34.8213 19.703 34.8213 14.7444 34.8151C14.6608 34.8077 14.577 34.8266 14.5047 34.8692C14.4325 34.9118 14.3755 34.9758 14.3418 35.0524C13.905 35.8254 13.452 36.5894 13.0126 37.3611C12.9832 37.4297 12.9331 37.4874 12.8692 37.5263C12.8053 37.5652 12.7307 37.5833 12.656 37.5781C8.77369 37.5725 0.75769 37.5581 0.75769 37.5581L22.1721 0.564453Z" fill="currentcolor" />
                <path d="M22.1738 18.4609L24.7431 22.7219L27.3132 26.9835H22.1738H17.0343L19.6037 22.7219L22.1738 18.4609Z" fill="var(--bs-body-bg)" />
                <path d="M72.5648 29.0641H66.0611L64.9918 32.1258H62.2638L67.8071 16.9258H70.819L76.3406 32.1258H73.6342L72.5648 29.0641ZM71.7356 26.7189L69.313 19.8572L66.8904 26.7189H71.7356Z" fill="currentcolor" />
                <path d="M87.7521 25.4596V32.1259H85.3951V25.6984C85.3951 24.0482 84.4349 23.1795 82.9508 23.1795C81.4014 23.1795 80.179 24.0915 80.179 26.3064V32.1259H77.8221V21.2688H80.179V22.6584C80.8992 21.5292 82.0779 20.9863 83.5618 20.9863C86.0061 20.9864 87.7521 22.6367 87.7521 25.4596Z" fill="currentcolor" />
                <path d="M101.28 16.9258V32.1258H98.9228V30.5623C98.0934 31.7132 96.8058 32.408 95.1035 32.408C92.1353 32.408 89.6693 29.911 89.6693 26.6972C89.6693 23.4618 92.1353 20.9863 95.1035 20.9863C96.8058 20.9863 98.0934 21.6812 98.9228 22.8104V16.9258H101.28ZM98.9228 26.6972C98.9228 24.6778 97.4388 23.2229 95.4745 23.2229C93.5101 23.2229 92.0262 24.6777 92.0262 26.6972C92.0262 28.7166 93.5104 30.1715 95.4745 30.1715C97.4386 30.1715 98.9228 28.7166 98.9228 26.6972Z" fill="currentcolor" />
                <path d="M109.748 21.0723V23.6129C108.199 23.5044 106.387 24.2209 106.387 26.653V32.125H104.031V21.2678H106.387V23.0918C107.042 21.6152 108.373 21.0723 109.748 21.0723Z" fill="currentcolor" />
                <path d="M110.38 26.6957C110.376 25.5609 110.71 24.4505 111.34 23.5049C111.971 22.5593 112.869 21.8212 113.922 21.384C114.974 20.9468 116.133 20.8302 117.252 21.0489C118.371 21.2677 119.4 21.812 120.208 22.6128C121.016 23.4137 121.567 24.4351 121.791 25.5477C122.015 26.6603 121.902 27.8141 121.467 28.8628C121.031 29.9116 120.293 30.8081 119.345 31.439C118.397 32.0698 117.282 32.4066 116.141 32.4065C115.384 32.4152 114.633 32.2735 113.931 31.9899C113.23 31.7062 112.592 31.2862 112.056 30.7545C111.519 30.2227 111.095 29.5899 110.807 28.893C110.519 28.1961 110.374 27.4491 110.38 26.6957ZM119.546 26.6957C119.538 26.0275 119.331 25.3768 118.951 24.8254C118.572 24.274 118.037 23.8466 117.415 23.5968C116.792 23.347 116.108 23.286 115.451 23.4215C114.793 23.5571 114.191 23.8831 113.719 24.3586C113.247 24.834 112.927 25.4377 112.799 26.0936C112.671 26.7496 112.741 27.4285 113 28.045C113.259 28.6616 113.695 29.1881 114.254 29.5585C114.813 29.9289 115.47 30.1265 116.141 30.1266C116.594 30.1339 117.044 30.0499 117.463 29.8795C117.882 29.7091 118.263 29.456 118.581 29.1353C118.899 28.8146 119.149 28.4331 119.315 28.0138C119.481 27.5945 119.559 27.1461 119.546 26.6957Z" fill="currentcolor" />
                <path d="M123.717 18.1429C123.717 17.8465 123.805 17.5568 123.971 17.3104C124.136 17.064 124.371 16.872 124.646 16.7586C124.922 16.6452 125.224 16.6155 125.517 16.6733C125.809 16.7311 126.077 16.8738 126.288 17.0834C126.498 17.2929 126.642 17.5599 126.7 17.8506C126.758 18.1412 126.728 18.4425 126.614 18.7163C126.5 18.9901 126.307 19.2241 126.059 19.3887C125.812 19.5533 125.521 19.6412 125.223 19.6412C124.824 19.6404 124.441 19.4823 124.159 19.2015C123.876 18.9207 123.718 18.54 123.717 18.1429ZM124.044 21.2698H126.401V32.1269H124.044V21.2698Z" fill="currentcolor" />
                <path d="M140.129 16.9258V32.1258H137.772V30.5623C136.943 31.7132 135.655 32.408 133.953 32.408C130.985 32.408 128.519 29.911 128.519 26.6972C128.519 23.4618 130.985 20.9863 133.953 20.9863C135.655 20.9863 136.943 21.6812 137.772 22.8104V16.9258H140.129ZM137.772 26.6972C137.772 24.6778 136.288 23.2229 134.324 23.2229C132.359 23.2229 130.875 24.6777 130.875 26.6972C130.875 28.7166 132.36 30.1715 134.324 30.1715C136.288 30.1715 137.772 28.7166 137.772 26.6972Z" fill="currentcolor" />
                <path d="M159.899 21.6378C159.899 24.3086 157.891 26.3281 155.142 26.3281H151.104V32.1258H149.947V16.9258H155.141C157.891 16.9258 159.899 18.9452 159.899 21.6378ZM158.743 21.6378C158.743 19.5315 157.237 18.0115 155.142 18.0115H151.104V25.2423H155.142C157.237 25.2423 158.743 23.7224 158.743 21.6378Z" fill="currentcolor" />
                <path d="M160.926 26.6976C160.919 25.5693 161.249 24.4645 161.874 23.5231C162.5 22.5816 163.391 21.846 164.437 21.4092C165.483 20.9725 166.635 20.8543 167.748 21.0696C168.861 21.285 169.885 21.8242 170.69 22.619C171.495 23.4138 172.045 24.4284 172.27 25.5343C172.495 26.6401 172.385 27.7876 171.954 28.8312C171.523 29.8749 170.79 30.7678 169.849 31.3969C168.907 32.026 167.8 32.363 166.666 32.3651C165.912 32.3764 165.164 32.2378 164.466 31.9576C163.767 31.6773 163.132 31.2609 162.597 30.733C162.062 30.205 161.639 29.5761 161.352 28.8831C161.065 28.1901 160.92 27.4471 160.926 26.6976ZM171.271 26.6976C171.264 25.7902 170.988 24.905 170.476 24.1538C169.964 23.4026 169.241 22.819 168.396 22.4766C167.551 22.1343 166.624 22.0486 165.73 22.2304C164.837 22.4121 164.017 22.8532 163.375 23.4979C162.733 24.1425 162.298 24.9619 162.124 25.8527C161.949 26.7434 162.044 27.6655 162.396 28.5026C162.748 29.3398 163.342 30.0544 164.102 30.5563C164.861 31.0582 165.754 31.3249 166.666 31.3228C167.275 31.3269 167.879 31.2098 168.443 30.9783C169.006 30.7467 169.518 30.4055 169.947 29.9746C170.376 29.5437 170.714 29.0319 170.941 28.4691C171.168 27.9063 171.28 27.3041 171.271 26.6976Z" fill="currentcolor" />
                <path d="M174.998 16.2735H176.09V32.125H174.999L174.998 16.2735Z" fill="currentcolor" />
                <path d="M179.122 17.771C179.122 17.5992 179.173 17.4312 179.269 17.2884C179.365 17.1456 179.501 17.0342 179.661 16.9685C179.82 16.9027 179.996 16.8855 180.165 16.919C180.335 16.9525 180.49 17.0353 180.612 17.1567C180.734 17.2782 180.818 17.433 180.851 17.6014C180.885 17.7699 180.868 17.9446 180.802 18.1033C180.736 18.262 180.624 18.3977 180.48 18.4931C180.337 18.5886 180.168 18.6396 179.995 18.6396C179.88 18.6398 179.767 18.6175 179.661 18.5739C179.555 18.5304 179.458 18.4664 179.377 18.3857C179.296 18.305 179.232 18.2091 179.188 18.1036C179.144 17.9982 179.122 17.8851 179.122 17.771ZM179.449 21.267H180.541V32.1242H179.449L179.449 21.267Z" fill="currentcolor" />
                <path d="M183.161 26.6967C183.161 23.483 185.606 21.0293 188.901 21.0293C191.04 21.0293 192.873 22.115 193.659 23.8088L192.764 24.3299C192.131 22.9619 190.647 22.0715 188.901 22.0715C187.672 22.0777 186.496 22.5677 185.629 23.4345C184.762 24.3012 184.276 25.4741 184.276 26.6967C184.276 27.9193 184.762 29.0922 185.629 29.9589C186.496 30.8256 187.672 31.3157 188.901 31.3218C189.701 31.3404 190.491 31.1396 191.184 30.7412C191.877 30.3429 192.446 29.7625 192.829 29.0636L193.746 29.6065C192.873 31.2785 191.04 32.3642 188.901 32.3642C185.606 32.3642 183.161 29.9105 183.161 26.6967Z" fill="currentcolor" />
                <path d="M206.34 26.7402C206.34 26.9139 206.318 27.0876 206.318 27.2613H196.431C196.693 29.715 198.57 31.3219 201.058 31.3219C202.848 31.3219 204.201 30.4967 204.834 29.3459L205.794 29.9105C204.899 31.3871 203.197 32.3642 201.036 32.3642C197.676 32.3642 195.297 29.9755 195.297 26.6967C195.297 23.5048 197.61 21.0293 200.949 21.0293C204.266 21.0294 206.34 23.7653 206.34 26.7402ZM196.431 26.1973H205.205C204.965 23.5482 203.088 22.0716 200.949 22.0716C198.461 22.0717 196.65 23.7871 196.431 26.1974V26.1973Z" fill="currentcolor" />
            </g>
        </svg>
    );
}