import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const DigitContainer = styled.div`
    font-style: normal;
    font-weight: 800;
    color: #58BF43 !important;
    margin-bottom: 0;
    font-size: 64px;
    font-family: Gilroy;
    height: 70px;
    line-height: 70px;
    text-align: center;

    @media(min-width: 992px) {
        font-size: 80px;
    }

    @media(prefers-color-scheme: dark) {
        color: #FFFFFF !important;
    }
`;

interface IDigitsContainerProp {
    digit: string;
}

const DigitScroller = styled.div`
    overflow: hidden;
    max-height: 70px;
    margin-top: 50px;
`;

const Content = styled.div<{ digit: string }>`
    display: flex;
    flex-direction: column;
    transform: translateY(-${props => Number(props.digit) * 70}px);
    transition: transform 1s ease-out;

`;

const DigitsContainer = ({ digit }: IDigitsContainerProp) => {
    return (
        <DigitScroller>
            <Content digit={digit}>
                <DigitContainer>0</DigitContainer>
                <DigitContainer>1</DigitContainer>
                <DigitContainer>2</DigitContainer>
                <DigitContainer>3</DigitContainer>
                <DigitContainer>4</DigitContainer>
                <DigitContainer>5</DigitContainer>
                <DigitContainer>6</DigitContainer>
                <DigitContainer>7</DigitContainer>
                <DigitContainer>8</DigitContainer>
                <DigitContainer>9</DigitContainer>
            </Content>
        </DigitScroller>
    );
};

interface IProps {
    number?: string;
}

export default function Counter(props: IProps) {
    const [digits, setDigits] = useState<string[]>([]);

    useEffect(() => {
        if (props.number) {
            setDigits(props.number.split('').reverse());
        }
    }, [props.number]);

    return (
        <div className='d-flex flex-row-reverse align-items-end justify-content-center justify-content-lg-end'>
            {digits.map((digit, index) => {
                if (index % 3 === 2 && index !== digits.length -1) {
                    return (
                        <>
                            <DigitsContainer digit={digit} />
                            <DigitContainer>,</DigitContainer>
                        </>
                    );
                }
                return <DigitsContainer digit={digit} />
            })}
        </div>
    );
}