import Layout from 'components/Layout';
import CustomerReviews from 'components/homepageSections/CustomerReviews';
import ExperienceEnte from 'components/homepageSections/ExperienceEnte';
import FAQs from 'components/homepageSections/FAQs';
import Features from 'components/homepageSections/Features';
import HeroSection from 'components/homepageSections/HeroSection';
import MemoriesPreserved from 'components/homepageSections/MemoriesPreserved';
import MentionsTicker from 'components/homepageSections/MentionsTicker';
import Pricing from 'components/homepageSections/Pricing';
import WhyEnte from 'components/homepageSections/WhyEnte';
import { PageProps, graphql } from 'gatsby';
import React from 'react';
import 'styles/landing.css';
import { FluidImageData } from 'types';

interface IPageData {
    featureImage: FluidImageData;
    featureDarkImage: FluidImageData;
    feature1: FluidImageData;
    feature2: FluidImageData;
    feature3: FluidImageData;
    feature4: FluidImageData;
    featureDark1: FluidImageData;
    featureDark2: FluidImageData;
    featureDark3: FluidImageData;
    featureDark4: FluidImageData;
}

const Index: React.FC<PageProps<IPageData>> = ({ path, data: { 
    featureImage,
    featureDarkImage,
    feature1,
    feature2,
    feature3,
    feature4,
    featureDark1,
    featureDark2,
    featureDark3,
    featureDark4,
} }) => {
    return (
        <Layout {...{ path }}>
            <HeroSection images={[featureImage, featureDarkImage]} />
            <MentionsTicker />
            <MemoriesPreserved />
            <WhyEnte />
            <CustomerReviews />
            <Features images={[feature1, feature2, feature3, feature4, featureDark1, featureDark2, featureDark3, featureDark4]} />
            <Pricing />
            <ExperienceEnte />
            <FAQs />
        </Layout>
    );
};

export default Index;

export const pageQuery = graphql`
    query HeaderImageQuery {
        featureImage: imageSharp(fluid: {originalName: {eq: "feature.png"}}) {
            fluid(quality: 70) {
                srcSetWebp
                aspectRatio
            }
        }
        featureDarkImage: imageSharp(fluid: {originalName: {eq: "feature-dark.png"}}) {
            fluid(quality: 70) {
                srcSetWebp
                aspectRatio
            }
        }
        feature1: imageSharp(fluid: {originalName: {eq: "feature-1.png"}}) {
            fluid(quality: 70) {
                srcSetWebp
                aspectRatio
            }
        }
        feature2: imageSharp(fluid: {originalName: {eq: "feature-2.png"}}) {
            fluid(quality: 70) {
                srcSetWebp
                aspectRatio
            }
        }
        feature3: imageSharp(fluid: {originalName: {eq: "feature-3.png"}}) {
            fluid(quality: 70) {
                srcSetWebp
                aspectRatio
            }
        }
        feature4: imageSharp(fluid: {originalName: {eq: "feature-4.png"}}) {
            fluid(quality: 70) {
                srcSetWebp
                aspectRatio
            }
        }
        featureDark1: imageSharp(fluid: {originalName: {eq: "feature-dark-1.png"}}) {
            fluid(quality: 70) {
                srcSetWebp
                aspectRatio
            }
        }
        featureDark2: imageSharp(fluid: {originalName: {eq: "feature-dark-2.png"}}) {
            fluid(quality: 70) {
                srcSetWebp
                aspectRatio
            }
        }
        featureDark3: imageSharp(fluid: {originalName: {eq: "feature-dark-3.png"}}) {
            fluid(quality: 70) {
                srcSetWebp
                aspectRatio
            }
        }
        featureDark4: imageSharp(fluid: {originalName: {eq: "feature-dark-4.png"}}) {
            fluid(quality: 70) {
                srcSetWebp
                aspectRatio
            }
        }
    }
`